import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Stack } from '@mui/material';
// components
import Logo from '../../components/logo';
import './_index.scss';
import { StyledRoot, StyledSectionBg, StyledSection } from './styles';
import LanguagePopover from '../dashboard/header/LanguagePopover';
export default function LoginLayout({ children, illustration, isRegister = false, title }) {
    return (_jsxs(StyledRoot, { className: "login-layout", children: [_jsx(Logo, { isRegister: true, sx: {
                    zIndex: 9,
                    position: 'absolute',
                    mt: { xs: 1.5, md: 2 },
                    ml: { xs: 2, md: 5 },
                } }), _jsx(Stack, { sx: {
                    zIndex: 9,
                    position: 'absolute',
                    mt: { xs: 1, md: 2 },
                    mr: { xs: 2, md: 5 },
                    top: 0,
                    right: 0,
                }, children: _jsx(LanguagePopover, {}) }), _jsxs(StyledSection, { className: "login-layout-wrapper", children: [_jsx(StyledSectionBg, {}), _jsx(Stack, { sx: { width: 1 }, className: "login-page-content", children: children })] })] }));
}
